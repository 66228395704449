import { AppProps } from "next/app";

import "@afa-shared/afa-components/dist/index.css";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import "@public/print.css";

import "../styles/cookieTractor.css";
import "../styles/globals.css";

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  return (
    <ContentfulLivePreviewProvider
      locale="sv-SE"
      enableInspectorMode
      enableLiveUpdates={false}
      debugMode={false}
    >
      <Component {...pageProps} />
    </ContentfulLivePreviewProvider>
  );
};

export default App;
